import React from "react";
import Layout from "../components/layout";
import "../Assets/styles/about.scss";
import ServiceFooter from "../components/ServiceFooter";

//images
import backgroundCard from "./../Assets/images/About/rectangle-background.png";
import rightDotTestomials from "./../Assets/images/Home/testimonials/right-dot.png";
import TitleDescription from "../components/TitleDescription/titleDescription";
import { graphql } from "gatsby";
import { Fade } from "react-reveal";
import Scrolltop from "../components/Scrolltotop/scrolltop";
import Footer from "../components/Footer/Footer";

const ContactPage = ({ data }) => {
  const { allAboutusHeaders, allOurCultures, allOurValues, allOurValuesCultures, allWhyWorkWithUs,
    allJoinOurTeams } = data
  return (
    <Layout>
      <div className="about-us">
        <div className="header-title">
          <div className="we-help-client">
            {allAboutusHeaders.nodes[0].data.attributes.title}
          </div>
          <Fade top>
          <div className="to-glow">
            {allAboutusHeaders.nodes[0].data.attributes.content1}
          </div>
          </Fade>
          <div className="their-business">
            {allAboutusHeaders.nodes[0].data.attributes.content2}
          </div>
        </div>

        <div className="business-sucess">
          {allAboutusHeaders.nodes[0].data.attributes.content3}
        </div>
        <div className="why-work">
          <TitleDescription
            title={allWhyWorkWithUs.nodes[0].data.attributes.title}
            description={allWhyWorkWithUs.nodes[0].data.attributes.description}
          />

          <div className="why-work__cards">
            <img src={backgroundCard} className="why-work__card-background" alt="green-background" />
            <img src={rightDotTestomials} className="why-work__card-background-dot" alt="dot" />
            <div className="why-work__cards-section">
              <Fade right>
              <div className="why-work__card-1">
                <h1>{allOurValuesCultures.nodes[0].data.attributes.valueTitle}</h1>
                <p>{allOurValuesCultures.nodes[0].data.attributes.valueDesc}</p>
                {allOurValues.nodes[0].data.map((card, index) => {
                  return (
                    <div key={index}>
                      <h5>{card.attributes.title}</h5>
                      <div className="why-work__card-description">
                        {card.attributes.description}
                      </div>
                    </div>
                  )
                })}
              </div>
              </Fade>
              <Fade left>
              <div className="why-work__card-2">
                <h1>{allOurValuesCultures.nodes[0].data.attributes.cultureTitle}</h1>
                <p>{allOurValuesCultures.nodes[0].data.attributes.cultureDesc}</p>
                {allOurCultures.nodes[0].data.map((card, index) => {
                  return (
                    <div key={index}>
                      <h5>{card.attributes.title}</h5>
                      <div className="why-work__card-description">
                        {card.attributes.description}
                      </div>
                    </div>
                  )
                })}
              </div>
              </Fade>
            </div>
          </div>

          <TitleDescription
            title={allJoinOurTeams.nodes[0].data.attributes.title}
            description={allJoinOurTeams.nodes[0].data.attributes.description}
          />
        </div>
        {/* <ServiceFooter /> */}
        <Footer />
      </div>
      {/* <ContactFooter /> */}
      <Scrolltop />
    </Layout>
  );
};

export const query = graphql`
  {
    allAboutusHeaders {
      nodes {
        data {
          attributes {
            title
            content1
            content2
            content3
          }
        }
      }
    }

    allOurCultures {
      nodes {
        data {
          attributes {
            title
            description
          }
        }
      }
    }

    allOurValues {
      nodes {
        data {
          attributes {
            title
            description
          }
        }
      }
    }

    allOurValuesCultures {
      nodes {
        data {
          attributes {
            valueTitle
            valueDesc
            cultureTitle
            cultureDesc
          }
        }
      }
    }

    allWhyWorkWithUs {
      nodes {
        data {
          attributes {
            title
            description
          }
        }
      }
    }

    allJoinOurTeams {
      nodes {
        data {
          attributes {
            title
            description
          }
        }
      }
    }
  }
`;

export default ContactPage;
